import { Backdrop } from "@mui/material";
import styles from './OmniChannel.module.css'
import { ReactComponent as LoaderIcon } from "assets/icons/loader-onmiChannel.svg";
import { OmniChart } from './Chart'

import { Form, Select } from 'antd';
import DatePicker from "components/datepicker/DatePicker";
import { addDaysToDate } from 'utility/time'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getOmniReports, selectOmniReport, selectFranchise, getFranchise, selectBenchmark, setBenchmark, setTendency, selectCredentialValidation } from 'store/Franchise/FranchiseStore'
import { getCustomChannels, selectCustomChannels } from "store/CustomReport/CustomReportStore";
import { selectReportChannels } from "../../../store/Channels/ChannelStore";

import OmniStats from './OmniStats';
import { setSelectedMainMenu, setSelectedSubMenu } from "store/Navigation/NavigationStore";
import { selectLocations } from "store/Location/LocationStore";
import { FranchiseOmniChannelMain } from "services/franchiseService";
import { getParamValue, updateURLParams } from "utility/url";

export const OmniChannel = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  var moment = require('moment'); // require
  const [dailyBudget, setDailyBudget] = useState(0);
  const [dailySpend, setDailySpend] = useState(0);

  const [customReportCpc, setCustomReportCpc] = useState(0);
  const [customReportCtr, setCustomReportCtr] = useState(0);

  const [daysDifference, setDaysDifference] = useState(moment().diff(moment().startOf('month'), 'days'))


  dispatch( setSelectedMainMenu("report") );
  dispatch( setSelectedSubMenu("Performance Overview"));
  

  useEffect( () => {
    dispatch( setBenchmark("my-trends"));
    dispatch( setTendency("impressions"));
  },[])

  //Store values
  
  const omniReport = useSelector(selectOmniReport);
  const benchmark = useSelector(selectBenchmark);

  const locations = useSelector(selectLocations);


  //Component state
  const [showDateFilters, setShowDateFilters] = useState(false);
  const [reportReady, setReportReady] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState('');
  const [selectedChannelName, setSelectedChannelName] = useState('');
  const [form] = Form.useForm();

  //JBEC470
  const channels = useSelector(selectReportChannels);
  console.log('all franchise channels');
  console.log(channels);


  //Listeners
  const onDateRangeChange = (value: string) => {
    let dateStart : any;
    let dateEnd : any;

    switch (value) {
      case 'mtd':
        dateStart = moment().startOf('month');
        dateEnd = moment();
        break;
      case 'ytd':
        dateStart = moment().startOf('year');
        dateEnd = moment();
        break;
      case 'fourteen':
      default:
        dateStart = moment().subtract(14, 'days');
        dateEnd = moment();
        break;
    }
    setDaysDifference(dateEnd.diff(dateStart, 'days'));


    if (value == 'custom') {
      setShowDateFilters(true);
    } else if( value != "ytd") {
      if(showDateFilters) setShowDateFilters(false); // Only change if the DateRangeFilter is enable
      dispatch( setBenchmark("my-trends") )
    }
    else {
      setShowDateFilters(false);
    }

    form.setFieldsValue({dateRange: value});
    updateURLParams({dateRange: value});
    setLoading(true);
    form.submit();
  }

  function daysSinceEarliestDate(omniChannelMain: FranchiseOmniChannelMain): number {
    let earliestDate: moment.Moment | null = null;
    let latestDay: moment.Moment | null = null;
    const channelReports = omniChannelMain.channelReports;

    // Find the earliest date
    channelReports.forEach(channelReport => {
        channelReport.locationReports.forEach(locationReport => {
            locationReport.dates.forEach(dateObj => {
                const currentDate = moment(dateObj.date);
                if (!earliestDate || currentDate.isBefore(earliestDate)) {
                    earliestDate = currentDate;
                }
            });

            latestDay = locationReport.dates.length > 0
            ? moment(locationReport.dates.reduce((prev, current) => prev > current ? prev : current).date)
            : null;
        });
    });

    if (!earliestDate) {
        return 0; //The default difference if no dates are found for any reason.
    }
    if (!latestDay) {
        return 0; //The default difference if no dates are found for any reason.
    }

    // Calculate the difference in days
    const differenceInDays = moment(latestDay).add(1, 'day').diff(earliestDate, 'days');
    return differenceInDays;
  }

  useEffect( () => {
    if (benchmark === "system") {
      onDateRangeChange("ytd")
    }
  }, [benchmark] ) 

  const onFinish = (e: any) => {
    //Dispatch the report
    dispatch(getOmniReports({
      channelName: e.channelName,
      dateRange: e.dateRange,
      startDate: e.startDate,
      endDate: e.endDate
    }));
  }

  //  Sets the defaultValue and defaultOptions
  var defaultValue = "";
  const defaultOptions: any = [];

  if(defaultValue == "" && channels.length > 0){
    defaultValue = channels[0].id;
  }

  defaultValue = getParamValue('channel') || defaultValue;

  //Listen to changes on the omniReport object
  useEffect(() => {

    console.log(omniReport)

    if (omniReport.error) console.log(omniReport.error);
    if (omniReport.omniChannel != undefined) {
      setReportReady(true);
      setLoading(false);
    }

    const currentStartDate = getParamValue('startDate');
    const currentEndDate = getParamValue('endDate');
    const currentChannel = getParamValue('channel');

    //Init values
    //This should go in a useEffect
    form.setFieldsValue({
      channelName: currentChannel || omniReport.lastChannelName || 'all',
      dateRange: omniReport.lastDateRange || getParamValue('dateRange') || 'fourteen',
      startDate: new Date( currentStartDate || omniReport.lastStartDate || addDaysToDate(new Date(), -28)),
      endDate: new Date( currentEndDate || omniReport.lastEndDate || new Date() )
    });

    //Calculate the CTR and CPC data
    if (omniReport.omniChannel != undefined) {
      const clicks = omniReport.omniChannel.clicks
      setCustomReportCpc( clicks == 0 ? 0 : omniReport.omniChannel.spend / clicks)
      setCustomReportCtr( clicks / omniReport.omniChannel.impressions)
    }
    else { // set the customs CPC and CTR to 0 just for prevent some bugs
      if(customReportCpc !== 0 && customReportCtr !== 0) {
        setCustomReportCpc(0);
        setCustomReportCtr(0);
      }
    }

    let socialDailySum = 0;
    let searchDailySum = 0;

    for( const location of locations ){
      socialDailySum = socialDailySum + Number(location.socialDaily);
      searchDailySum = searchDailySum + Number(location.searchDaily);
    }
      
    if (omniReport.omniChannel) {
      if( selectedChannelName != undefined && omniReport.omniChannel.spend != 0)
      {
        if ( selectedChannelName === "Search" ) 
        {
          setDailyBudget(searchDailySum)
        }
        else if ( selectedChannelName === "Social" )
        {
          setDailyBudget(socialDailySum)
        }
      }

      // set the value to 0 if there is no spend value or 0 in the omniChannel
      if(omniReport.omniChannel.spend == 0) {
        if(dailySpend != 0) setDailySpend(0);
        if(dailyBudget != 0) setDailyBudget(0);
      }
      else{
        const calculatedDayDifference = daysSinceEarliestDate(omniReport.omniChannel);
        if( calculatedDayDifference != 0 ){
          setDailySpend( Number(((isNaN(omniReport.omniChannel.spend) ? 0 : omniReport.omniChannel.spend) / 1000000).toFixed(2)) / calculatedDayDifference )  
        }
      }
    }
  }, [omniReport])


  //Gets the omniReport only if the defaultValue has been assigned
  useEffect(()=>{
    setLoading(true);
    if (reportReady == false && defaultValue != "") {
      
      if( omniReport.lastDateRange === "custom"){
        form.setFieldsValue({
          channelName: defaultValue,
          dateRange: getParamValue('dateRange') || 'fourteen',
          startDate: addDaysToDate(new Date(), -28),
          endDate: new Date()
        });
        
        setSelectedChannel(defaultValue);
        const channelName = defaultOptions.find( (opt: {value: string; label: string;}) => opt.value === defaultValue )?.label
        setSelectedChannelName( channelName )
        setLoading(true);
        form.submit();
        
      }else{
        setSelectedChannel(defaultValue);
        const channelName = defaultOptions.find( (opt: {value: string; label: string;}) => opt.value === defaultValue )?.label
        setSelectedChannelName( channelName )

        const currentDateRange = getParamValue('dateRange');
        let startDate = undefined;
        let endDate = undefined;

        if (currentDateRange === 'custom') {
          setShowDateFilters(true);
          startDate = getParamValue('startDate');
          endDate = getParamValue('endDate');
        }

        dispatch(getOmniReports({
          channelName: defaultValue,
          dateRange: getParamValue('dateRange') || omniReport.lastDateRange || 'fourteen',
          startDate: new Date( startDate || omniReport.lastStartDate || addDaysToDate(new Date(), -28)).toISOString(),
          endDate: new Date( endDate || omniReport.lastEndDate || new Date() ).toISOString(),
          userId: getParamValue('user_id') || undefined
        }));
      }
    }
  }, [defaultValue])
  

  //Getting custom channels and Franchise data after the first render 
  useEffect(() => {
      dispatch(getCustomChannels());
      dispatch(getFranchise());
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  const graphOptions = [
    { label: 'Meta', value: 'meta' },
    { label: 'Google', value: 'google' },
  ]

  //JBEC470
  if (channels.length > 0) {
    channels.forEach(channel => {
      const newOption = { label: channel.title, value: channel.id };
      defaultOptions.push(newOption);
      graphOptions.push(newOption);
    });
  }

  const handleChannelChange = ( event : string ) => {
    setLoading(true);
    setSelectedChannelName( defaultOptions.find( (opt: {value: string; label: string;}) => opt.value === event ).label )
    setSelectedChannel(event)
    form.submit();
    updateURLParams({channel: event});
  };

  const handleStartDateChange = (event: any) => {
    setLoading(true);
    updateURLParams({startDate: event as string});
    form.submit();
  };

  const handleEndDateChange = (event: any) => {
    setLoading(true);
    updateURLParams({endDate: event as string});
    form.submit();
  };

   return (
    <div>
      <div className={styles['d-grid'] + " res:!flex res:flex-wrap "}>
        <div className={styles['channel-selector-container'] + " res:order-1"}>
          <Form
              form={form}
              name='basic'
              onFinish={onFinish}
              onFinishFailed={() => { }}
              autoComplete='off'
              className={styles['filter-main-container']}
              layout='vertical'
            >
              <div className={styles['filter-labels-container']}>
              </div>
            <Form.Item
                name="channelName"
                className={styles['filter-input']}
              >
              <Select
                showArrow
                style={{ borderRadius: "4px", width: "100%" }}
                onChange={handleChannelChange}
                options={defaultOptions}
              />
            </Form.Item>
            <Form.Item
              name="dateRange"
              className={styles['filter-input']}
            >
              <Select
                showArrow
                style={{ borderRadius: "4px", width: "100%" }}
                onChange={onDateRangeChange}
                options={[
                  { label: 'Last 14 days', value: 'fourteen' },
                  { label: 'Month to date', value: 'mtd' },
                  { label: 'Year to date', value: 'ytd' },
                  { label: 'Custom', value: 'custom' }  // Custom Date
                ]}
              />
            </Form.Item>

            <Form.Item
              name="startDate"
              className={styles['filter-input']}
              style={{ display: showDateFilters ? 'inline-block' : 'none' }}
              rules={[{ required: false }]}
            >
              <DatePicker
                use12Hours={true}
                showTime={false}
                showHour={true}
                showMinute={true}
                showSecond={false}
                style={{ borderRadius: "4px", width: "100%" }}
                onChange={handleStartDateChange}
              />
            </Form.Item>

            <Form.Item
              name="endDate"
              className={styles['filter-input']}
              style={{ display: showDateFilters ? 'inline-block' : 'none' }}
              rules={[{ required: false }]}
            >
              <DatePicker
                use12Hours={true}
                showTime={false}
                showHour={true}
                showMinute={true}
                showSecond={false}
                style={{ borderRadius: "4px", width: "100%" }}
                onChange={handleEndDateChange}
              />
            </Form.Item>
          </Form>
        </div>

       

        <div className={styles['graph-container'] + "  py-0 h-auto res:order-3 res:max-w-[97vw] res:overflow-x-scroll "}>
          {
            reportReady && <OmniChart 
              selectedChannel={selectedChannel} 
              customChannelOptions={graphOptions} 
              defaultChannel={omniReport.omniChannel.channelReports[0].name} 
              channelReports={omniReport.omniChannel.channelReports} />
          }
        </div>
        {reportReady && <OmniStats
          impressions={omniReport.omniChannel.impressions}
          pageViews={omniReport.omniChannel.pageViews}
          conversions={omniReport.omniChannel.conversions}
          pmConversions={omniReport.omniChannel.pmConversions}
          clicks={omniReport.omniChannel.clicks}
          ctr={omniReport.omniChannel.ctr > 0 ? omniReport.omniChannel.ctr : customReportCtr}
          cpc={omniReport.omniChannel.cpc > 0 ? omniReport.omniChannel.cpc : customReportCpc}
          cpl={omniReport.omniChannel.cpl}
          spend={omniReport.omniChannel.spend}
          daily_budget={dailyBudget}
          daily_spend={dailySpend}
          channelSelected={ selectedChannelName }
        ></OmniStats>}
{/*        {reportReady && <div className={styles['bottom-data-container'] + " res:order-4 res:max-w-full res:overflow-x-scroll"}>
          <TendencyTable rows={omniReport.omniChannel.channelReports} />
        </div>}*/}

      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <LoaderIcon className="loader-icon" />
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>

    </div>


  )
}

export default OmniChannel
