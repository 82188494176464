import React from "react";
import ReactDOM from "react-dom";
import "@fontsource/mulish";
import App from "./App";
import AdminApp from "./AdminApp";
import { Provider } from "react-redux";
import awsconfig from "./awsConfig";
import Amplify from "aws-amplify";
import { store, persistor } from "./store/store";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import TagManager from 'react-gtm-module';
import { PersistGate } from 'redux-persist/es/integration/react'
import "./index.css";
Amplify.configure(awsconfig);

const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripeKey || "");

const tagManagerArgs = {
  gtmId: 'GTM-NT4VDNZH'
}
TagManager.initialize(tagManagerArgs)

//Check if the route starts with /admin, if so return the admin component instead.
const currentPathname = window.location.pathname;
var mainComponent;

if (currentPathname.startsWith('/plat-admin')) {
  mainComponent = <AdminApp />;
} else {
  mainComponent = <App />;
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Elements stripe={stripePromise}>
          {mainComponent}
        </Elements>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
