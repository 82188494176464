import { Alert } from "./Alert";
import { PaymentMethod } from "./PaymentMethod";

interface ActiveChannelPair{
  search?: string,
  social?: string,
  display?: string,
  email?: string,
  ctv?: string,
  native?: string,
  [key: string]: string | undefined
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string | null;
  type: UserRole;
  tier: Tier;
  paymentId?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  country?: string | null;
  communicationPreference?: UserCommunicationPreference | null;
  paymentMethods?: PaymentMethod[];
  alerts?: Alert[];
  franchiseId: string;
  canSwitchRole: boolean;
  canSwitchBrand: boolean;
  activeChannels: ActiveChannelPair[];
}

export interface ICreateUser {
  firstName: string;
  lastName: string;
  email: string;
  type: UserRole;
}
export interface IUpdateUser {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  country?: string | null;
  communicationPreference?: UserCommunicationPreference | null;
}

export interface ICustomerPayment {
  cardId: string;
}
export interface IConfirmUser {
  email: string;
}

export enum UserRole {
  Franchisee = "franchisee",
  Franchisor = "franchisor",
  Agency = "agency",
}

export enum Tier{
  Base = "base",
  Plus = "plus"
}

export enum UserCommunicationPreference {
  Email = "email",
  SMS = "SMS",
  Both = "both",
}
