import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { setOpen as setUploadOpen } from "../../../store/PlatformSupport/Upload";
import { setOpen as setDownloadOpen } from "../../../store/PlatformSupport/Download";
import { setOpen as setSupportFormOpen } from "../../../store/PlatformSupport/SupportForm";
import { selectIsSendingState, setOpen as setOrderFormOpen } from "../../../store/PlatformSupport/OrderForm";
import { FileUpload } from "./FileUpload";
import { FileDownload } from "./FileDownload";
import { SupportForm } from "./SupportForm";
import { OrderForm } from "./OrderForm";
import { Backdrop } from "@mui/material";
import { ReactComponent as SendingIcon } from "../../../assets/icons/loader-sending.svg";

const SystemTools = () => {
  const dispatch = useDispatch();
  const sending = useSelector(selectIsSendingState);
  return (
      <>
          <div className="mb-10 ">
              <h1 className="mb-3 text-2xl">Insertion orders</h1>
              <OrderForm />
              <Button
                  className="primary-button pointer-hover"
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  onClick={() => {
                    dispatch(setOrderFormOpen(true));
                  }}
              >
                <div className="primary-button-text">NEW ORDER</div>
              </Button>
          </div>
          <div>
              <h1 className="mb-3 text-2xl">File exchange</h1>
              <FileUpload />
              <FileDownload />
              <p>
                  <Button
                      className="primary-button pointer-hover"
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      onClick={() => {
                          dispatch(setDownloadOpen(true));
                      }}
                  >
                    <div className="primary-button-text">DOWNLOAD</div>
                  </Button>
              </p>
              <p>
                  <Button
                      className="primary-button pointer-hover"
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      onClick={() => {
                          dispatch(setUploadOpen(true));
                      }}
                  >
                    <div className="primary-button-text">UPLOAD</div>
                  </Button>
              </p>
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={sending}
          >
            <SendingIcon className="loader-icon" />
            {/* <CircularProgress color="inherit" /> */}
          </Backdrop>
      </>
  );
};

export default SystemTools;

