import { applyMiddleware, createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { rootReducer } from "./index";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "@redux-devtools/extension";
import { getDefaultMiddleware } from "@reduxjs/toolkit";

// Persist configuration for account slice
const persistConfig = {
  key: "root",
  storage,
  whitelist: ['campaignForm'],
  blacklist: ['authReducer'],
  debug: false,
};

const loggerMiddleware = (store:any) => (next:any) => (action: any) => {
  console.log('Dispatching:', action);
  try {
    const result = next(action);
    console.log('Next state:', store.getState());
    return result;
  } catch (e) {
    console.trace(e)
    throw e;
  }
};

const middlewares = [
  // loggerMiddleware,
  ...getDefaultMiddleware({
    serializableCheck: false,
    thunk: true,
    immutableCheck: false
  })
];


export const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  {},
  composeWithDevTools(
    applyMiddleware(...middlewares)
  )
);


export const persistor = persistStore(store);
export default { store, persistor };