import { RadioGroup } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Location } from "../../../models/Location";
import {
  selectSelectableLocations,
  selectSelectedLocation,
  setDetailsLocation,
  setSelectedLocation,
  setSingleLocation
} from "../../../store/campaignForm/CampaignForm";
import { selectFranchiseLocations, selectLocations, selectMarketLocations } from "../../../store/Location/LocationStore";
import LocationCard from "./LocationCard";
import "./styles.css";
import {
  selectFranchiseeLocations, selectUserAccount,
} from "../../../store/Account/AccountStore";
import { UserRole } from "../../../models/User";

const Locations = () => {
  const user = useSelector(selectUserAccount);
  const userLocations = useSelector(selectLocations);
  const franchiseLocations = useSelector(selectFranchiseLocations);
  const marketLocations = useSelector(selectMarketLocations);
  const dispatch = useDispatch();
  const franchiseeLocations = useSelector(selectFranchiseeLocations);
  const nextStepLocation = useSelector(selectSelectedLocation);
  const selectableLocations = useSelector(selectSelectableLocations);

  function locationSelect(locationId: string) {
    let selectedLocation: Location | null;
    if (selectableLocations === 'FRANCHISE') {
      selectedLocation =
        franchiseLocations.find((location) => location.id === locationId) ??
        null;
    } else if (selectableLocations === 'FRANCHISEE') {
      selectedLocation =
        franchiseeLocations.find(
          (location) => location.id === locationId
        ) ?? null;
    } else {//selectableLocations === 'USER'
      selectedLocation =
        userLocations.find((location) => location.id === locationId) ??
        null;
    }
    dispatch(setDetailsLocation(selectedLocation));
    dispatch(setSelectedLocation(selectedLocation));
  }

  useEffect(() => {
    dispatch(setDetailsLocation(null));
  }, [selectableLocations, franchiseeLocations]);

  const locationChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    locationSelect(ev.target.value);
  };

  useEffect(() => {
    if (!nextStepLocation) {
      dispatch(setDetailsLocation(null));
    }
  }, [nextStepLocation]);

  const showDetails = (location: Location) => {
    dispatch(setDetailsLocation(location));
  }

  const renderLocations = (locations: Location[]) => {
    if (locations.length === 0) {
      return <h2>Loading locations</h2>;
    }
    if ( user?.type == UserRole.Franchisee && locations.length == 1) {
      locationSelect(locations[0].id);
      dispatch(setSingleLocation(true));
    } else {
      dispatch(setSingleLocation(false));
    }
    return (
      <RadioGroup
        style={{ width: "100%", flexDirection: "row" }}
        onChange={locationChange}
        value={nextStepLocation?.id}
      >
        {locations.map((location) => (
          <LocationCard
            key={`location card: ${location.id}`}
            location={location}
            canEdit={false}
            defaultChecked={locations.length == 1}
            showDetails={() => showDetails(location)}
            disableTooltip={true}
          />
        ))}
      </RadioGroup>
    );
  };

  return (
    <div>
      <div className="location-background">
        <div className="locations-container">
          {selectableLocations === 'USER' && renderLocations(userLocations)}
          {selectableLocations === 'FRANCHISE' && renderLocations(franchiseLocations)}
          {selectableLocations === 'FRANCHISEE' && renderLocations(franchiseeLocations)}
          {selectableLocations === 'MARKET' && renderLocations(marketLocations)}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Locations);
